import React from "react";
import ThankYouPage from "../../components/thank-you/thank-you";

export default ({location}) => {

    return (<ThankYouPage location={location}>
            <h1>Thank you!</h1>

            <p>Your eBook will be delivered to your Inbox shortly.</p>
            <p>Wishing you an amazing day further!</p>
            <p><a href="/resources">View our additional Resources </a></p>
        </ThankYouPage>
    );
}